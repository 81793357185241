//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventCalendar from '@/components/Event/EventCalendar.vue'
import EmptyMessage from '@/components/EmptyMessage'

export default {
    name: "EventList",
    components: {
        EmptyMessage,
        EventCalendar,
        EventMicroView: () => import('@/components/Event/EventMicroView.vue'),
        LeftSidebar: () => import('@/components/Sidebars/LeftSidebar.vue')
    },
    metaInfo(){
        return {
            title: 'My Events'
        }
    },
    data(){
        return {
            user_id: this.$store.state.user.id,
            eventlist: null,
            pagination: '',
            isEmpty: false,
            image: '',
            isLoading: true,
            calendar_events: null,
        }
    },

    methods: {
        getEventList(id){
            this.$store.dispatch('event/fetchEventList', id)
            .then((r) =>{
                if(r.status == 'success'){
                    this.eventlist = r.eventlist
                    this.pagination = r.pagination
                }else{
                    this.isEmpty = true
                    this.isLoading = false;
                }
                this.$store.commit('event/sortCalendarEvents',this.eventlist)
                this.calendar_events = this.$store.state.event.calendar_events
                this.isLoading = false;
            })
        },
        deleteEvent(id){
            this.$q.dialog({
                title:'Delete Event',
                message: 'Are you sure you want to delete this event?',
                cancel: true,
            })
            .onOk(() => {
                this.$store.dispatch('event/deleteEvent',id)
                .then((r) =>{
                    if(r == 'success'){
                        this.$q.notify({
                            message: 'The event has been deleted.',
                            color: 'primary',
                            icon: 'event_busy'
                        })
                        location.reload()
                    }
                })
            })
            .onCancel(() =>{})
            .onDismiss(() => {})
        }
    },
    created() {
        this.getEventList(this.$store.state.user.id)
        
    },
    
}
