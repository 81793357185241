//
//
//
//
//
//
//
//
//

export default {
    name: 'EventBanner',
    props: ['id'],
    data(){
        return{
            image: null,
            isLoading: true,
        }
    },
    methods: {
        loadBanner(id) {
            if(id){
                let payload = {id: id}
                this.$store.dispatch('event/getCoverPhoto', payload)
                .then((response) =>{
                    if(response.status == 'success'){
                        this.image = response.image
                        this.isLoading = false;
                    }
                })
            }
        }
    },
    created(){
        this.loadBanner(this.id)
    }
}
