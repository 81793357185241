//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import EventBanner from '@/components/Event/EventBanner.vue'
import EmptyMessage from '@/components/EmptyMessage'
export default {
    name: "EventCalendar",
    props: ['calendar_events'],
    components:{ EmptyMessage, EventBanner },
    data(){
        return {
            splitterModel: 50,
            date: moment(new Date()).format('YYYY/MM/DD'),
        }
    },
     filters: {
        filterEventDate: function (date,date_end) {
           return date_end == date ? moment(new Date(date)).format('MMMM DD, YYYY') :   moment(new Date(date)).format('MMMM DD, YYYY') + ' - ' + moment(new Date(date_end)).format('MMMM DD, YYYY')
        },
        formatTime( time ){
            if(time){
                return moment(time,"HH:mm").format("LT")
            }else{
                return '00:00'
            }
        }
    },
}
